import { Button, useColorMode } from '@chakra-ui/react'
import React from 'react'
import toast from 'react-hot-toast'
import { trpc } from '../../../utils/trpc'

export const AdminUserFlagButtons: React.FC<{
  user: {
    hasBeenBanned: boolean
    hasBeenFlagged: boolean
    isShadowBanned: boolean
    internalFlagged: boolean
    isTrustedBuyer: boolean
  }
  userWallet: string
  refetch: () => any
}> = ({ user, userWallet, refetch }) => {
  const { colorMode } = useColorMode()
  const isDarkMode = colorMode === 'dark'
  const flagUserMut = trpc.useMutation('user.flagUser', {
    onSuccess: async () => {
      await refetch()
      toast.success('User flag status toggled')
    },
  })
  const banUserMut = trpc.useMutation('user.banUser', {
    onSuccess: async () => {
      await refetch()
      toast.success('User ban status toggled')
    },
  })
  const toggleInternalFlagMut = trpc.useMutation('user.toggleInternalFlag', {
    onSuccess: async () => {
      await refetch()
      toast.success('User internal flag status toggled')
    },
  })
  const toggleShadowBanMut = trpc.useMutation('user.toggleShadowBanUser', {
    onSuccess: async () => {
      await refetch()
      toast.success('User shadow ban status toggled')
    },
  })

  const toggleUserAsTrusted = trpc.useMutation('user.toggleAsTrusted', {
    onSuccess: async () => {
      await refetch()
      toast.success('User as trusted toggled')
    },
  })

  const banUserDB = async () => {
    await banUserMut.mutateAsync({
      wallet: userWallet,
      banned: !user.hasBeenBanned,
    })
  }
  const flagUserDB = async () => {
    await flagUserMut.mutateAsync({
      wallet: userWallet,
      flag: !user.hasBeenFlagged,
    })
  }
  const shadowBanUserDB = async () => {
    await toggleShadowBanMut.mutateAsync({
      wallet: userWallet,
      shadowBan: !user.isShadowBanned,
    })
  }
  const flagInternalUserDB = async () => {
    await toggleInternalFlagMut.mutateAsync({
      wallet: userWallet,
      flag: !user.internalFlagged,
    })
  }
  const toggleUserAsTrustedDB = async () => {
    await toggleUserAsTrusted.mutateAsync({
      wallet: userWallet,
      trusted: !user.isTrustedBuyer
    })
  }

  return (
    <>
      <Button
        minWidth='140px'
        maxWidth='220px'
        colorScheme={'red'}
        isLoading={banUserMut.isLoading}
        onClick={banUserDB}
      >
        {user.hasBeenBanned ? 'Unban' : 'Ban'}
      </Button>
      <Button
        minWidth='140px'
        maxWidth='220px'
        colorScheme='yellow'
        isLoading={flagUserMut.isLoading}
        onClick={flagUserDB}
      >
        {user.hasBeenFlagged ? 'Unflag' : 'Flag'}
      </Button>
      <Button
        minWidth='140px'
        maxWidth='220px'
        colorScheme='teal'
        isLoading={toggleShadowBanMut.isLoading}
        onClick={shadowBanUserDB}
      >
        {user.isShadowBanned ? 'Unshadow ban' : 'Shadow ban'}
      </Button>
      <Button
        minWidth='140px'
        maxWidth='220px'
        colorScheme='facebook'
        isLoading={toggleInternalFlagMut.isLoading}
        onClick={flagInternalUserDB}
      >
        {user.internalFlagged ? 'Unflag internal' : 'Flag internal'}
      </Button>

      <Button
        minWidth='140px'
        maxWidth='220px'
        colorScheme='green'
        isLoading={toggleUserAsTrusted.isLoading}
        onClick={toggleUserAsTrustedDB}
      >
        {user.isTrustedBuyer ? 'Untrust user' : 'Set trusted user'}
      </Button>
    </>
  )
}

export const AdminUserStateAndFlagButtons: React.FC<{ userWallet: string }> = ({
  userWallet,
}) => {
  const userAdminViewRes = trpc.useQuery([
    'user.user-admin-view',
    { wallet: userWallet },
  ])

  return !userAdminViewRes.data ? (
    <></>
  ) : (
    <AdminUserFlagButtons
      userWallet={userWallet}
      user={userAdminViewRes.data}
      refetch={userAdminViewRes.refetch}
    ></AdminUserFlagButtons>
  )
}

export default AdminUserFlagButtons
