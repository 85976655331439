import { FC, useMemo, useEffect, useState } from 'react'
import {
  Stack,
  Box,
  HStack,
  VStack,
  Text,
  Image,
  useColorMode,
  Skeleton,
} from '@chakra-ui/react'
import { useProjectBySlug } from '../../techRaffles/hooks/project'
import { useUser } from '../../common/auth/authHooks'
import { useUserByWalletInRouter } from '../../techRaffles/hooks/user'
import { useRouter } from 'next/router'

export const GemCard: FC = () => {
  const { colorMode } = useColorMode()
  const isDarkMode = colorMode === 'dark'
  const [data, setData] = useState<any>()

  const router = useRouter()
  const { data: pathUser } = useUserByWalletInRouter()
  const { data: walletUser } = useUser()

  useEffect(() => {
    console.log(router)

    if (router.pathname === '/rewardsCenter') {
      console.log('setting')
      console.log(walletUser)
      setData(walletUser)
    } else {
      setData(pathUser)
    }

    console.log(data)
  }, [pathUser, walletUser])

  const loading = useMemo(() => {
    return !data
  }, [data])

  if (loading) {
    return <Skeleton w='full' h='260px' borderRadius='20px' />
  } else {
    return (
      <>
        <Stack
          w={['full']}
          minHeight={'260px'}
          border='solid'
          borderRadius='20px'
          borderColor={isDarkMode ? '#494949' : '#E9E9E9'}
          bgColor={isDarkMode ? 'cardBlack' : '#FAFAFA'}
          justifyContent='center'
          alignItems='center'
          flexDirection={['column']}
          paddingY='5'
          gap='3'
        >
          <Image src='/images/rewards/GemsShine.svg' w={20} />
          <Stack
            px={2}
            flexDirection={['column']}
            alignItems={['center']}
          >
            <Text fontWeight='bold' fontSize='20px'>
              {Math.floor(data?.gems)}
            </Text>
            <Text color='#7B7B7B'> Gems Balance</Text>
            <Text textAlign={'center'} fontSize='.8rem' color='#8e8e8e'>
              Gems can be spent to enter the weekly draw
            </Text>
          </Stack>
        </Stack>
      </>
    )
  }
}
