import {
  HStack,
  VStack,
  Stack,
  Skeleton,
  Input,
  Flex,
  Button,
  useColorMode,
  FormControl,
  FormLabel,
  Text,
  Tooltip,
  Badge,
  Image,
  Box,
  Link,
  SkeletonCircle,
} from '@chakra-ui/react'
import toast from 'react-hot-toast'
import { IoGlobeOutline } from 'react-icons/io5'
import { AiFillTwitterCircle } from 'react-icons/ai'
import { BsDiscord, BsTelegram } from 'react-icons/bs'
import { BiDotsHorizontalRounded } from 'react-icons/bi'
import { BsFillPatchCheckFill } from 'react-icons/bs'
import { FaDiscord, FaTwitter } from 'react-icons/fa'
import { GoThumbsup } from 'react-icons/go'
import { FormInputWithMode } from '../../../components/Forms/FormInputWithMode'
import { FC, useState, useMemo, useCallback, useEffect } from 'react'
import { ProfilePicture } from '../../dApp/components/ProfilePicture'
import { DiscordLinkMultiButton } from '../../admin/components/discord-link-ui/DiscordLinkMultiButton'
import { TwitterLinkMultiButton } from '../../admin/components/twitter-link-ui/TwitterLinkMultiButton'
import {
  useUserByWalletInRouter,
  useWalletFromRouter,
} from '../../techRaffles/hooks/user'
import { useWallet } from '@solana/wallet-adapter-react'
import { useIsUserPlatformAdmin } from '../../common/auth/authHooks'
import { trpc } from '../../../utils/trpc'
import { EditModal } from './EditModal'
import { useProjectBySlug } from '../../techRaffles/hooks/project'
import { HiVariable } from 'react-icons/hi'
import { GrClose } from 'react-icons/gr'
import { XpBar } from './XpBar'
import { GemCard } from './GemCard'
import { BadgesCard } from './BadgesCard'
import { useWalletSignInModal } from '../../../components/wallet-ui/useWalletSignInModal'
import { useUser } from '../../common/auth/authHooks'
import { useModal } from '@chakra-ui/react'
import { IconType } from 'react-icons'
import { FiLink2 } from 'react-icons/fi'
import { useRouter } from 'next/router'
import { SignMessageToLoginButton } from '../../dApp/components/SignMessageToLoginButton'
import { AdminUserStateAndFlagButtons } from '../../platformAdmin/components/AdminUserFlagBox'

export const ProfileCard: FC<{ variant: string }> = ({ variant }) => {
  // const variant = 'user'
  const { colorMode } = useColorMode()
  const isDarkMode = colorMode === 'dark'

  const { publicKey: userPubKey } = useWallet()
  const walletFromRouter = useWalletFromRouter()
  const loggedInWallet = useMemo(() => {
    return userPubKey?.toBase58()
  }, [userPubKey])

  const { isAuthed, data: connectedUser } = useUser()

  const [data, setData] = useState<any>()

  const router = useRouter()
  const { data: walletUser } = useUserByWalletInRouter()
  const {
    data: pathUser,
    refetch,
    isLoading: isLoadingPathUser,
  } = useUserByWalletInRouter()
  const { data: projectData } = useProjectBySlug()

  const [profileType, setProfileType] = useState<string>()

  const isLoggedIn = useMemo(
    () => walletUser?.wallet === loggedInWallet && !!walletUser?.wallet,
    [walletUser]
  )
  const [editModal, setEditModal] = useState(false)

  const { data: isPlatformAdmin, isLoading: isPlatformAdminLoading } =
    useIsUserPlatformAdmin()

  // useEffect(() => {
  //   console.log('PROJECT', projectData)
  //   console.log('admin', isPlatformAdmin)
  // }, [projectData, isPlatformAdminLoading])

  useEffect(() => {
    if (router.pathname.includes('/p')) {
      setData(projectData)
      setProfileType('project')
      console.log(projectData)
    } else if (router.pathname.includes('/u')) {
      setData(pathUser)
      setProfileType('user')
    } else {
      setData(null)
    }
    console.log('data', data)
  }, [pathUser, walletUser, router.pathname, projectData])

  const loadingUser = useMemo(() => {
    return !pathUser
  }, [pathUser])

  const banUserMut = trpc.useMutation('user.banUser', {
    onSuccess: () => {
      toast.success('User banned successfully!')
      refetch()
    },
    onError: () => {
      toast.error('Could not ban user')
    },
  })

  const [editUsername, setEditUsername] = useState(false)
  const [username, setUsername] = useState(pathUser?.name || '')


  // const checkFollowing = () => {
  //   const isFollowing = walletUser?.following.some(
  //     (follow) => follow.followerId === user?.id
  //   )
  //   setFollowing(isFollowing)
  //   console.log('is following?', isFollowing)
  // }

  // useEffect(() => {
  //   checkFollowing()
  // }, [user, walletUser])

  const [pfp, setPfp] = useState<{
    url: string
    isLoading: boolean
  }>({ url: '', isLoading: false })

  const usernameShort = useMemo(() => {
    if (!pathUser) {
      return ''
    }
    if (pathUser.name.length < 26) {
      return pathUser.name
    }
    return pathUser.name.slice(0, 6) + '...' + pathUser.name.slice(-6)
  }, [pathUser])

  const setFollowMut = trpc.useMutation('user.follow', {
    onSuccess: () => {
      toast.success('User followed')
      void refetch()
    },
    onError: () => {
      toast.error('Couldnt follow user')
    },
  })

  const setUnfollowMut = trpc.useMutation('user.unfollow', {
    onSuccess: () => {
      toast.success('User unfollowed')
      void refetch()
    },
    onError: () => {
      toast.error('Couldnt unfollow user')
    },
  })

  const setLikeMut = trpc.useMutation('user.likePage', {
    onSuccess: () => {
      toast.success('Liked')
      void refetch()
    },
    onError: () => {
      toast.error('Couldnt like')
    },
  })

  // const setUnlikeMut = trpc.useMutation('user.unlikePage', {
  //   onSuccess: () => {
  //     toast.success('unliked')
  //     void refetch()
  //   },
  //   onError: () => {
  //     toast.error('Couldnt unlike')
  //   },
  // })

  const CustomLinks = () => {
    const links = ['tensor flow', 'contract link', 'test3', 'test4', 'test5']

    const Link: FC<{
      title: string
      url: string
    }> = ({ children, title, url }) => {
      return (
        <Button
          display='flex'
          justifyContent='flex-start'
          alignItems='center'
          gap='5'
          paddingLeft='5'
          paddingRight='5'
          maxWidth='250px'
          margin='0px !important'
          onClick={() => window.open(url, '_blank')}
        >
          <FiLink2 size={25} color={isDarkMode ? 'white' : 'black'} />
          <Text>{title}</Text>
        </Button>
      )
    }

    if (!data) {
      return <Skeleton w='full' h='150px' borderRadius='20px' />
    } else {
      return (
        <Stack
          flexDirection='row'
          gap='3'
          w={['full']}
          maxW='600px'
          marginTop={['6px', '12px']}
          alignSelf='flex-start'
          flexWrap='wrap'
          paddingBottom={'2rem'}
        >
          {profileType === 'user'
            ? data.userLinks?.map((link: any) => {
                return <Link title={link.name} url={link.url} />
              })
            : data.projectLinks?.map((link: any) => {
                return <Link title={link.name} url={link.url} />
              })}
        </Stack>
      )
    }
  }

  const [liked, setLiked] = useState(false)

  const setPfpMut = trpc.useMutation('user.setPfp', {
    onSuccess: () => {
      toast.success('Pfp updated successfully!')
      void refetch()
    },
    onError: () => {
      toast.error('Error updating pfp.')
    },
  })

  useEffect(() => {
    // console.log(connectedUser)
    const result = connectedUser?.likedBy.some((record: any) => {
      // console.log('record.followerId', record.likedId.toString())
      // console.log('user?.id', pathUser?.id.toString())
      // console.log('user?.id', pathUser?.id.toString())
      if (record.likedId == pathUser?.id) {
        // console.log('Match found!')
        return true
      } else {
        return false
      }
    })
    setLiked(result || false)
  }, [connectedUser, pathUser])

  const handleLikePage = () => {
    const result = setLikeMut.mutate({
      likedWallet: pathUser!.wallet,
    })
    // console.log('RESULT', result)
    setLiked(true)
  }

  const handleUnlikePage = () => {
    // const result = setUnlikeMut.mutate({
    //   likedWallet: pathUser!.wallet,
    // })
    // console.log('RESULT', result)
    setLiked(true)
  }

  const Like = () => {
    if (!liked) {
      return (
        <Button
          w='120px'
          margin='0 !important'
          variant={isDarkMode ? 'outlinedDark' : 'outlined'}
          onClick={handleLikePage}
        >
          <GoThumbsup
            size={25}
            opacity='.3'
            color={isDarkMode ? 'white' : 'black'}
          />
        </Button>
      )
    } else {
      return (
        <Button
          w='120px'
          margin='0 !important'
          variant={isDarkMode ? 'outlinedDark' : 'outlined'}
          onClick={handleUnlikePage}
        >
          <GoThumbsup size={25} color={isDarkMode ? 'white' : 'black'} />
        </Button>
      )
    }
  }

  const [following, setFollowing] = useState(false)

  useEffect(() => {
    const result = connectedUser?.following.some((record: any) => {
      // console.log('record.followerId', record.followerId.toString())
      // console.log('user?.id', pathUser?.id.toString())
      // console.log('user?.id', pathUser?.id.toString())
      if (record.followerId == pathUser?.id) {
        // console.log('Match found!')
        return true
      } else {
        return false
      }
    })
    setFollowing(result || false)
  }, [connectedUser, pathUser])

  const handleFollowUser = async () => {
    const result = await setFollowMut.mutateAsync({
      followedWallet: pathUser!.wallet,
    })
    console.log('RESULT', result)
    setFollowing(true)
  }

  const handleUnfollowUser = async () => {
    const result = await setUnfollowMut.mutateAsync({
      followedWallet: pathUser!.wallet,
    })
    // console.log(result)
    setFollowing(false)
  }

  const Follow = () => {
    if (walletUser?.wallet === pathUser?.wallet) {
      return <></>
    } else if (!following && walletUser?.wallet !== pathUser?.wallet) {
      return (
        <Button
          w='120px'
          variant={isDarkMode ? 'primaryDark' : 'primary'}
          isLoading={setFollowMut.isLoading}
          onClick={handleFollowUser}
        >
          Follow
        </Button>
      )
    } else {
      return (
        <Button
          w='120px'
          variant={isDarkMode ? 'primaryDark' : 'primary'}
          onClick={handleUnfollowUser}
          isLoading={setUnfollowMut.isLoading}
        >
          Unfollow
        </Button>
      )
    }
  }

  const { isLedger, visible, setVisible } = useWalletSignInModal()

  useEffect(() => {
    if (!visible) {
      toast.remove()
    }
  }, [visible])

  return (
    <Stack
      w='100%'
      align='start'
      bgColor={'rgba(0, 0, 0, 0.42)'}
      backdropFilter='5px'
      border='1px solid'
      borderColor={
        'rgba(0, 0, 0, 0.5)'
      }
      borderRadius='60px'
      px='2.5rem'
      paddingTop='3rem'
      direction={['column', 'column', 'row']}
      gap='6'
    >
      {editModal && (
        <EditModal editModal={editModal} setEditModal={setEditModal} />
      )}
      <VStack justifyContent={['flex-start', 'flex-start']} w='full'>
        <Stack
          direction={['column', 'row', 'row']}
          alignItems={['center', 'center', 'start']}
          gap='1.5rem'
          w='100%'
        >
          <Stack align={['center', 'center', 'start']}>
            {!data && <Skeleton h='100px' w='300px' borderRadius='20px' />}

            <ProfilePicture
              imageUrl={data?.profilePictureUrl ?? ''}
              gradientstart={data?.gradientStart ?? ''}
              gradientend={data?.gradientEnd ?? ''}
              minW='4rem'
              h='4rem'
              borderRadius='full'
            />
          </Stack>

          <VStack align='start'>
            {variant === 'user' && !loadingUser && (
              <>
                {editUsername && isLoggedIn ? (
                  <Flex alignItems='flex-end'>
                    {/* <FormControl>
                  <FormLabel as='legend' fontSize='.75rem'>
                    Username
                  </FormLabel>
                  <FormInputWithMode
                    fontSize='.875rem'
                    type='text'
                    value={username ?? ''}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setUsername(e.target.value)
                    }}
                    isInvalid={user?.name.length === 0}
                  />
                </FormControl>
                <Button
                  variant={isDarkMode ? 'outlinedDark' : 'outlined'}
                  ml='1rem'
                  onClick={() => updateUsernameDB()}
                >
                  Save
                </Button>
                <Button
                  variant={isDarkMode ? 'outlinedDark' : 'outlined'}
                  ml='1rem'
                  onClick={() => setEditUsername(!editUsername)}
                >
                  Cancel
                </Button>
                {user?.name !== user?.wallet && (
                  <Button
                    variant={isDarkMode ? 'outlinedDark' : 'outlined'}
                    ml='1rem'
                    onClick={() => setUsernameMut.mutate({})}
                  >
                    Reset
                  </Button>
                )} */}
                  </Flex>
                ) : (
                  <Flex
                    justifyContent={['center', 'center', 'start']}
                    alignItems={['center', 'center', 'flex-end']}
                    direction={['column', 'column', 'row']}
                    w='100%'
                  >
                    {!loadingUser && (
                      <HStack>
                        <VStack alignItems={['center', 'center', 'flex-start']}>
                          <Text fontWeight={600} fontSize='1.75rem'>
                            {usernameShort}
                          </Text>
                        </VStack>

                        {data?.isTrustedRaffler && (
                          <Tooltip label='Trusted Raffler'>
                            <Text as='span'>
                              <BsFillPatchCheckFill size={18} />
                            </Text>
                          </Tooltip>
                        )}

                        {!pathUser?.hasBeenBanned &&
                          !pathUser?.hasBeenFlagged &&
                          !pathUser?.isTrustedRaffler &&
                          (pathUser?._count.createdRaffles ?? 0) > 4 && (
                            <Tooltip label='Regular raffler'>
                              <Text as='span'>
                                <BsFillPatchCheckFill color='gray' size={25} />
                              </Text>
                            </Tooltip>
                          )}
                      </HStack>
                    )}
                    {/* {loading && <Skeleton h='40px' w='15rem' />}
                {isLoggedIn && (
                  <Button
                    variant={isDarkMode ? 'underlineDark' : 'underline'}
                    onClick={() => {
                      setUsername(user?.name ?? '')
                      setEditUsername(true)
                    }}
                  >
                    Change Username
                  </Button>
                )} */}
                  </Flex>
                )}
              </>
            )}
            {variant === 'project' && data && (
              <>
                <Flex
                  justifyContent={['center', 'center', 'start']}
                  alignItems={['center', 'center', 'flex-end']}
                  direction={['column', 'column', 'row']}
                  w='100%'
                >
                  <HStack alignItems='center'>
                    <VStack alignItems='flex-start' justifyContent='center'>
                      <HStack>
                        <Text fontWeight={600} fontSize='1.75rem' color='#fff'>
                          {data.platformName}
                        </Text>
                        {data.verified && (
                          <Tooltip label='Trusted Raffler'>
                            <Text as='span'>
                              <BsFillPatchCheckFill color='white' size={18} />
                            </Text>
                          </Tooltip>
                        )}
                      </HStack>
                      <Text
                        fontSize='14px'
                        fontWeight='bold'
                        marginTop='0px !important'
                        color='#fff'
                      >
                        @{data.communityName}
                      </Text>
                    </VStack>
                  </HStack>
                </Flex>
              </>
            )}

            <Flex
              gap={['0', '0', '4rem']}
              direction={['column', 'column', 'row']}
              w='100%'
              color={isDarkMode ? 'white' : 'black'}
            >
              {data?.twitterUsername && (
                <HStack justifyContent={['center', 'center', 'start']}>
                  <FaTwitter color={isDarkMode ? 'white' : 'black'} />
                  <Link
                    isExternal
                    href={`https://twitter.com/${data.twitterUsername}`}
                  >
                    @{data.twitterUsername}
                  </Link>
                </HStack>
              )}
              {data?.discordUsername && (
                <HStack justifyContent={['center', 'center', 'start']}>
                  <FaDiscord color={isDarkMode ? 'white' : 'black'} />
                  <Text>{data.discordUsername}</Text>
                </HStack>
              )}
              <HStack spacing={3}>
                {data?.hasBeenBanned && (
                  <Badge colorScheme={'red'}>Banned raffler</Badge>
                )}

                {data?.hasBeenFlagged && (
                  <Badge colorScheme={'yellow'}>Flagged raffler</Badge>
                )}
              </HStack>
            </Flex>
          </VStack>
          {data ? (
            <Stack
              alignSelf='center'
              alignItems={'center'}
              flexDirection={['row', 'column', 'row']}
              gap='2'
            >
              <Follow />
              {/*  <Like /> */}
            </Stack>
          ) : (
            <Skeleton />
          )}
        </Stack>

        <Flex w='100%' justifyContent={'flex-start'}>
          {isPlatformAdmin && profileType === 'user' && walletFromRouter && (
            <HStack w='100%' mt='1rem' gap={3} wrap='wrap'>
              <AdminUserStateAndFlagButtons userWallet={walletFromRouter}></AdminUserStateAndFlagButtons>
            </HStack>
          )}
        </Flex>

        {profileType === 'project' && data && (
          <VStack w='full' gap='0'>
            <Text alignSelf='flex-start' marginTop='0' color={'#e7e7e7'}>
              Official Links
            </Text>
            <HStack mt='0 !important' w='full'>
              {data.discordInviteLink && (
                <Button p='0 !important' bg='transparent'>
                  <a
                    target='_blank'
                    rel='noopener noreferrer'
                    href={data.discordInviteLink}
                  >
                    <FaDiscord color={'white'} size={20} />
                  </a>
                </Button>
              )}

              {data.twitterUserHandle && (
                <Button p='0 !important' bg='transparent'>
                  <a
                    target='_blank'
                    rel='noopener noreferrer'
                    href={`https://twitter.com/${data.twitterUserHandle}`}
                  >
                    <FaTwitter color={'white'} size={20} />
                  </a>
                </Button>
              )}

              {data.magicEdenSlug && (
                <Button p='0 !important' bg='transparent'>
                  <a
                    target='_blank'
                    rel='noopener noreferrer'
                    href={`https://magiceden.io/${data.magicEdenSlug}`}
                  >
                    <Image
                      src={`/icons/logo-me.svg`}
                      style={{ fill: 'white' }}
                    />
                  </a>
                </Button>
              )}

              {data.websiteUrl && (
                <Button p='0 !important' bg='transparent'>
                  <a
                    target='_blank'
                    rel='noopener noreferrer'
                    href={data.websiteUrl}
                  >
                    <IoGlobeOutline color={'white'} size={20} />
                  </a>
                </Button>
              )}
            </HStack>
          </VStack>
        )}

        {!data ? (
          <Skeleton w='full' h='100px' borderRadius='20px' />
        ) : (
          <Text
            color='#fcfcfc'
            alignSelf={['center', 'center', 'flex-start']}
            marginTop='30px !important'
            marginBottom='30px !important'
          >
            {data?.bio ? data.bio : ''}
          </Text>
        )}


        <CustomLinks />
      </VStack>
      <VStack w='full' maxWidth={['full', 'full', '500px']}>
        {isLoggedIn &&
          isAuthed &&
          !loadingUser &&
          data &&
          profileType === 'user' && (
            <Button
              alignSelf='flex-end'
              marginTop='-20px'
              variant={isDarkMode ? 'primaryDark' : 'primary'}
              onClick={() => setEditModal((state) => !state)}
            >
              Edit Profile
            </Button>
          )}

        {data && !isAuthed && isLoggedIn && (
          <HStack justifyContent={'flex-end'}>
            <Text>👋 Sign Message to make changes to profile.</Text>
            <SignMessageToLoginButton />
          </HStack>
        )}
        {variant === 'user' && (
          <Stack
            direction={['column', 'row', 'row']}
            alignItems={['stretch']}
            justifyContent={['center']}
            w='full'
            h='full'
          >
            !loadingUser && <GemCard />
            <XpBar variant={variant} />
          </Stack>
        )}
        {variant === 'user' && (
          <BadgesCard
            h='75px'
            stackProps={{}}
            variant={variant}
            badges={pathUser?.badges}
            isLoading={isLoadingPathUser}
          />
        )}
      </VStack>
    </Stack>
  )
}
