import { FC, useState } from 'react'
import { HStack, VStack, Text, useColorMode } from '@chakra-ui/react'

export const ContentTabs: FC<{
  tabs: string[]
  activeTab: string
  setActiveTab: React.Dispatch<React.SetStateAction<string>>
}> = ({ tabs, activeTab, setActiveTab }) => {
  const { colorMode } = useColorMode()
  const isDarkMode = colorMode === 'dark'

  return (
    <HStack
      borderBottom='4px'
      borderColor={isDarkMode ? '#494949' : '#E9E9E9'}
      w='full'
      gap={['2', '5']}
    >
      {tabs.map((tab: string) => {
        if (tab === activeTab) {
          return (
            <Text
              key={tab}
              fontSize='1em'
              zIndex={2}
              paddingBottom='4'
              borderBottom='4px solid '
              borderColor={!isDarkMode ? '#494949' : '#E9E9E9'}
              marginBottom='-4px !important'
              fontWeight='bold'
              cursor='pointer'
            >
              {tab}
            </Text>
          )
        } else {
          return (
            <Text
              key={tab}
              cursor='pointer'
              fontSize='1em'
              paddingBottom='4'
              onClick={() => setActiveTab(tab)}
            >
              {tab}
            </Text>
          )
        }
      })}
    </HStack>
  )
}
