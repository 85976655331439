import { FC, useEffect, useMemo } from 'react'
import {
  HStack,
  VStack,
  Text,
  Stack,
  useColorMode,
  Skeleton,
  Image,
} from '@chakra-ui/react'
import { useUserByWalletInRouter } from '../../techRaffles/hooks/user'
import { useProjectBySlug } from '../../techRaffles/hooks/project'
import { IconType } from 'react-icons'
import { formatFloatForDisplay } from '../../../utils/utils'

export const StatCard: FC<{
  Icon: IconType
  path?: string
  number?: string | number | null
  title: string
  variant: string
}> = ({ Icon, number, title, variant, path }) => {
  const { colorMode } = useColorMode()
  const isDarkMode = colorMode === 'dark'

  useEffect(() => {
    console.log(path)
  }, [path])

  const { data: user } = useUserByWalletInRouter()

  const projectRes = useProjectBySlug()

  const getLoading = () => {
    const userLoading = useMemo(() => {
      return !user
    }, [user])

    const loadingProject = useMemo(
      () => projectRes.isLoading,
      [projectRes.isLoading]
    )

    if (variant === 'user') {
      return userLoading
    } else if (variant === 'project') {
      return loadingProject
    }
  }

  const loading = getLoading()

  if (loading === true) {
    return (
      <Skeleton
        w='300px'
        h={['auto', 'auto', '120px']}
        borderRadius='20px'
        m={'0px !important'}
      />
    )
  } else {
    return !number ? (
      <></>
    ) : (
      <Stack
        borderWidth='2px'
        borderColor={isDarkMode ? '#494949' : '#E9E9E9'}
        borderRadius='20px'
        padding='16px 24px 16px 24px'
        gap={['0', '8px']}
        flexDirection={['column', 'row', 'row']}
        justifyContent={['center', 'center']}
        alignItems={['center', 'center']}
        h={['100%']}
        margin={'0px !important'}
        w={['100%', 'auto']}
        minW={['100px', '180px']}
      >
        {path ? (
          <Image
            src={path}
            width={7}
            className={isDarkMode ? 'white-svg' : 'black-svg'}
          />
        ) : (
          <Icon size={24} color={isDarkMode ? 'white' : 'black'} />
        )}
        <Stack mt={0} alignItems={['center', 'flex-start']}>
          <Text fontWeight='bold' fontSize='1.3rem'>
            {typeof number === 'number'
              ? formatFloatForDisplay(number)
              : number}
          </Text>
          <Text color='#7B7B7B' margin='0 !important'>
            {title}
          </Text>
        </Stack>
      </Stack>
    )
  }
}
