export const formLabelStyle: React.CSSProperties = {
  fontSize: '1rem',
  fontWeight: 600,
}

export const formInputStyle: React.CSSProperties = {
  borderRadius: '40px',
  fontSize: '1rem',
  fontWeight: 500,
  transition: 'all .2s ease-in-out',
}
