import 'react-alice-carousel/lib/alice-carousel.css'
import {
  Button,
  HStack,
  IconButton,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Image,
  Text,
  useDisclosure,
  VStack,
  Skeleton,
  Box,
  FormControl,
  FormLabel,
  Switch,
  Flex,
  useColorMode,
  SkeletonCircle,
  Input,
} from '@chakra-ui/react'
import React, {
  FC,
  useState,
  useMemo,
  useCallback,
  useEffect,
  Dispatch,
  SetStateAction,
  ChangeEvent,
  memo,
} from 'react'
import { IoAdd } from 'react-icons/io5'
import { Logo } from '../../dApp/components/Logo'
import { Player as Lottie } from '@lottiefiles/react-lottie-player'
import { useStartAuthTx } from '../../common/auth/startAuthHook'
import { useWalletSignInModal } from '../../../components/wallet-ui/useWalletSignInModal'
import { IoDiamondOutline } from 'react-icons/io5'
import { AiOutlineShareAlt } from 'react-icons/ai'
import { ProfilePicture } from '../../dApp/components/ProfilePicture'
import { BsFillImageFill } from 'react-icons/bs'
import { BiCamera, BiObjectsHorizontalCenter } from 'react-icons/bi'
import {
  useUserByWalletInRouter,
  useWalletFromRouter,
} from '../../techRaffles/hooks/user'
import { useWallet } from '@solana/wallet-adapter-react'
import { FormInputWithMode } from '../../../components/Forms/FormInputWithMode'
import { TwitterLinkMultiButton } from '../../admin/components/twitter-link-ui/TwitterLinkMultiButton'
import { DiscordLinkMultiButton } from '../../admin/components/discord-link-ui/DiscordLinkMultiButton'
import { getParsedFile } from '../../../utils/utils'
import { uploadFileToS3Hook } from '../../../utils/s3'
import toast from 'react-hot-toast'
import { trpc } from '../../../utils/trpc'
import { useS3Upload } from 'next-s3-upload'
import { AiOutlineClose, AiOutlineCheck } from 'react-icons/ai'
import { useUser } from '../../common/auth/authHooks'
import { set } from 'date-fns'
import id from 'date-fns/locale/id'
// import { Link } from '@prisma/client'
import { useProjectBySlug } from '../../techRaffles/hooks/project'
import { useRouter } from 'next/router'
import { platform } from 'os'

interface WebsiteLink {
  id: string | null
  name: string
  projectId: string | null
  url: string
  userId: string | null
}

const Field: FC<{
  value: any
  name: string
  submit: () => void
  onUpdate: (value: any) => void
}> = ({ value, submit, onUpdate, name }) => {
  const { colorMode } = useColorMode()
  const isDarkMode = colorMode === 'dark'
  // const [state, setState] = useState(value)
  const [updated, setUpdated] = useState(false)

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log('value', event.target.value)
    onUpdate(event.target.value)

    setUpdated(true)
  }

  return (
    <VStack w='full'>
      <FormLabel
        as='legend'
        fontSize='.75rem'
        margin='0px !important'
        alignSelf='flex-start'
      >
        {name}
      </FormLabel>
      <HStack w='full'>
        <FormInputWithMode
          type='text'
          value={value}
          onChange={handleNameChange}
          // isInvalid={value === 0}
        />
        {updated ? (
          <Button
            onClick={() => {
              submit()

              setUpdated(false)
            }}
            padding='0 !important'
          >
            <AiOutlineCheck size={16} color={isDarkMode ? 'white' : 'black'} />
          </Button>
        ) : (
          <></>
        )}
      </HStack>
    </VStack>
  )
}

const DualField: FC<{
  index: number
  value: any
  onUpdate: any
  submit: any
  deleteLink: any
}> = ({
  index,

  value,
  onUpdate,
  submit,
  deleteLink,
}) => {
  const { colorMode } = useColorMode()
  const isDarkMode = colorMode === 'dark'

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log('NAME', event.target.value)
    onUpdate({ ...value, name: event.target.value }, index)
    setUpdated(true)
  }

  const handleUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log('URL', event.target.value)
    onUpdate({ ...value, url: event.target.value }, index)
    setUpdated(true)
  }

  const [updated, setUpdated] = useState(false)

  return (
    <HStack key={index} alignItems='flex-end'>
      <VStack w='full'>
        <FormLabel
          as='legend'
          fontSize='.75rem'
          margin='0px !important'
          alignSelf='flex-start'
        >
          link {index + 1}
        </FormLabel>
        <HStack w='full'>
          <FormInputWithMode
            type='text'
            value={value.name}
            onChange={handleNameChange}
            // isInvalid={value === 0}
          />
        </HStack>
      </VStack>
      <VStack w='full'>
        <FormLabel
          as='legend'
          fontSize='.75rem'
          margin='0px !important'
          alignSelf='flex-start'
        >
          url {index + 1}
        </FormLabel>
        <HStack w='full'>
          <FormInputWithMode
            type='text'
            value={value.url}
            onChange={handleUrlChange}
            // isInvalid={value === 0}
          />
        </HStack>
      </VStack>
      <Button onClick={() => deleteLink(index)} p='0 !important'>
        <AiOutlineClose size={16} color={isDarkMode ? 'white' : 'black'} />
      </Button>
      {updated ? (
        <Button
          onClick={() => {
            submit(index)
            setUpdated(false)
          }}
          padding='0 !important'
        >
          <AiOutlineCheck size={16} color={isDarkMode ? 'white' : 'black'} />
        </Button>
      ) : (
        <></>
      )}
    </HStack>
  )
}

export const EditModal: FC<{
  className?: string
  container?: string
  editModal: any
  setEditModal: any
}> = ({ className, container, editModal, setEditModal }) => {
  const { colorMode } = useColorMode()
  const isDarkMode = colorMode === 'dark'
  // const { isLedger, visible, setVisible } = useWalletSignInModal()
  // const [isLedgerForm, setIsLedgerForm] = useState(isLedger ?? false)
  // const { startSignAuthMsg, startSignAuthTx } = useStartAuthTx()

  // const { isOpen, onClose } = useDisclosure()

  const { data: projectData, isLoading: isLoadingProject } = useProjectBySlug()
  const router = useRouter()

  const {
    isAuthed,
    data: user,
    isLoading: isLoadingUser,
    refetchUser,
  } = useUser()

  const [data, setData] = useState<any>()
  const [profileType, setProfileType] = useState('')
  const [editUsername, setEditUsername] = useState(false)
  const [showEditPic, setShowEditPic] = useState(false)
  const [showEditBanner, setShowEditBanner] = useState(false)
  const [links, setLinks] = useState<WebsiteLink[]>([])
  const [deletedLinks, setDeletedLinks] = useState<WebsiteLink[]>([])
  const [bio, setBio] = useState('')
  const [username, setUsername] = useState<string>()
  const [nameTag, setNameTag] = useState<string>()
  const [pfp, setPfp] = useState()
  const [heroImage, setHeroImage] = useState()
  const [loading, setLoading] = useState(true)
  const [gradientStart, setGradientStart] = useState()
  const [gradientEnd, setGradientEnd] = useState()

  const [selectedPfp, setSelectedPfp] = useState<{
    url: string
    isLoading: boolean
  }>({ url: '', isLoading: false })
  const [selectedHeroImage, setSelectedHeroImage] = useState<{
    url: string
    isLoading: boolean
  }>({ url: '', isLoading: false })

  useEffect(() => {
    console.log('DATA', data)
  }, [data])

  useEffect(() => {
    if (router.pathname.includes('/p')) {
      setProfileType('project')
      // setProfileData(projectData, 'project')
    } else if (router.pathname.includes('/u')) {
      setProfileType('user')
      // setProfileData(projectData, 'user')
    } else {
      setData(null)
    }
  }, [user, router.pathname, projectData, isLoadingProject, isLoadingUser])

  useEffect(() => {
    if (profileType === 'project') {
      setData(projectData)
    } else if (profileType === 'user') {
      setData(user)
    } else {
      setData(null)
    }
  }, [profileType])

  useEffect(() => {
    if (data) {
      setProfileData(data, profileType)
    }
  }, [data])

  useEffect(() => {}, [])

  const setProfileData = (data: any, profileType: string) => {
    console.log('DATA', data?.platformName)
    console.log('render')
    console.log(profileType)

    switch (profileType) {
      case 'project':
        setBio(data.bio)
        setPfp(data.ProfilePictureUrl)
        setHeroImage(data.bannerUrl)
        setUsername(data.platformName)
        setNameTag(data.communityName)
        setGradientStart(data.gradientStart)
        setGradientEnd(data.gradientEnd)
        const sanitizedProjectLinks = (data?.projectLinks ?? []).map(
          (link: WebsiteLink) => ({
            ...link,
            id: link.id || '',
          })
        )
        setLinks(sanitizedProjectLinks)
        setLoading(false)
        break
      case 'user':
        setUsername(data.name)
        setBio(data.bio)
        setPfp(data.ProfilePictureUrl)
        setHeroImage(data.bannerUrl)
        setGradientStart(data.gradientStart)
        setGradientEnd(data.gradientEnd)
        const sanitizedUserLinks = (data?.userLinks ?? []).map(
          (link: WebsiteLink) => ({
            ...link,
            id: link.id || '',
          })
        )
        setLinks(sanitizedUserLinks)
        setLoading(false)
        break
    }
  }

  // useEffect(() => {
  //   console.log('DATA', data)
  //   console.log()
  // }, [data])

  const { publicKey: userPubKey } = useWallet()
  const loggedInWallet = useMemo(() => {
    return userPubKey?.toBase58()
  }, [userPubKey])

  const { uploadToS3 } = useS3Upload()

  const setPfpMut = trpc.useMutation('user.setPfp', {
    onSuccess: () => {
      toast.success('Pfp updated successfully!')
      void refetchUser()
    },
    onError: () => {
      toast.error('Error updating pfp.')
    },
  })

  const setHeroMut = trpc.useMutation('user.setHero', {
    onSuccess: () => {
      toast.success('Banner updated successfully!')
      void refetchUser()
    },
    onError: () => {
      toast.error('Error updating banner')
    },
  })

  //Upload handle
  const uploadImage = useCallback(async (imageFile, setImage) => {
    if (!imageFile) return

    imageFile = getParsedFile(imageFile)

    setImage({ isLoading: true, url: '' })
    let url = await uploadFileToS3Hook(imageFile, uploadToS3)
    console.log(url)
    setImage({ isLoading: false, url: url })
    return url
  }, [])

  const updatePfp = useCallback(
    async (image: File) => {
      if (!image) return
      if (!user) throw new Error('No user')
      const url = await uploadImage(image, setSelectedPfp)
      setPfpMut.mutate({
        url: url,
      })
    },
    [user]
  )

  const updateHeroImage = useCallback(
    async (image: File) => {
      if (!image) return
      if (!user) throw new Error('No user')
      const url = await uploadImage(image, setSelectedHeroImage)
      setHeroMut.mutate({
        id: user.id,
        url: url,
      })
    },
    [user]
  )

  const emptyLink = {
    id: '', // Provide appropriate values for other properties
    name: '',
    url: '',
    userId: user?.id ?? '',
    projectId: '',
  }

  // useEffect(() => {
  //   const sanitizedUserLinks = (user?.userLinks ?? []).map((link) => ({
  //     ...link,
  //     userId: link.userId || '',
  //   }))

  //   setLinks(sanitizedUserLinks)
  //   setUsername(user?.name ?? '')
  //   setBio(user?.bio ?? '')
  // }, [user])

  const updateLinkMutation = trpc.useMutation('user.updateLink')
  const deleteLinkMutation = trpc.useMutation('user.deleteLink')
  const addLinkMutation = trpc.useMutation('user.addLink')
  const updateBioMutation = trpc.useMutation('user.updateBio')
  const updateUsernameMutation = trpc.useMutation('user.setUsername')

  const updateUsername = async () => {
    const res = await updateUsernameMutation.mutateAsync({
      name: username,
      // name: link.name,
      // url: link.url,
      // userId: link.userId,
      // projectId: link.projectId,
    })
    displayResult(res)
    // handleUpdate(index, '', 'element')
  }

  const updateBio = async () => {
    // console.log('BIO', bio)
    if (user) {
      const res = await updateBioMutation.mutateAsync({
        id: user.id,
        bio: bio,
      })
      displayResult(res)
    } else {
      toast.error('Couldnt find user')
    }

    // handleUpdate(index, '', 'element')
  }

  const displayResult = (res: any) => {
    console.log('res', res)
    if (res.success) {
      // Handle success
      console.log('Link added successfully.')
      toast.success(res.message)
    } else {
      // Handle error
      console.error('Error:', res.message)
      toast.error(res.message)
    }
  }

  const addLink = async (index: number) => {
    if (user) {
      const link = links[index]

      const res = await addLinkMutation.mutateAsync({
        name: link.name,
        url: link.url,
        userId: user.id,
        // projectId: link.projectId,
      })
      displayResult(res)
      if (res && res.dbRes) {
        handleLinkUpdate(res.dbRes, index)
      }
    } else {
      toast.error('Couldnt find user')
    }
  }

  const updateLink = async (index: number) => {
    const link = links[index]

    if (link.id) {
      const res = await updateLinkMutation.mutateAsync({
        id: link.id,
        name: link.name,
        url: link.url,
      })
      displayResult(res)
    } else {
      toast.error('Couldnt find link')
    }

    // handleUpdate(index, res.dbRes.id, 'id')
  }

  const deleteLink = async (index: number) => {
    const link = links[index]
    if (link.id) {
      const res = await deleteLinkMutation.mutateAsync({
        linkId: link.id,
        // name: link.name,
        // url: link.url,
        // userId: link.userId,
        // projectId: link.projectId,
      })
      displayResult(res)
    }

    handleLinkDelete(index)
  }

  // const handleUpdate = (
  //   index: number,
  //   value: string | boolean | undefined,
  //   key: string
  // ) => {
  //   setLinks((prevLinks) => {
  //     const updatedLinks: Link[] = [...prevLinks]

  //     if (key === 'element') {
  //       updatedLinks.splice(index, 1)
  //     } else {
  //       ;(updatedLinks[index] as any)[key] = value
  //     }
  //     return updatedLinks
  //   })
  // }

  const handleLinkDelete = (index: number) => {
    setLinks((prevLinks) => {
      let updatedLinks = [...prevLinks]
      updatedLinks.splice(index, 1)
      return updatedLinks
    })
  }

  const handleLinkUpdate = (link: WebsiteLink, index: number) => {
    console.log('HELLO', link)
    setLinks((prevLinks) => {
      const updatedLinks = [...prevLinks]
      updatedLinks[index] = link
      return updatedLinks
    })
  }

  const handleUsernameUpdate = (updatedValue: string) => {
    setUsername(updatedValue)
  }

  const handleBioUpdate = (updatedValue: string) => {
    setBio(updatedValue)
  }

  if (loading) {
    return <></>
  } else {
    console.log('username', username)
    return (
      <>
        <Modal isOpen={editModal} onClose={() => setEditModal(false)}>
          <ModalOverlay />
          <ModalContent
            background={isDarkMode ? '#1f2023' : '#fcfcfc'}
            maxWidth={['96%', '96%', '30rem']}
            borderRadius={30}
          >
            <ModalHeader textAlign='center'>
              <HStack
                width='100%'
                display='flex'
                justifyContent='space-between'
              >
                <HStack gap='3'>
                  <Button
                    onClick={() => {
                      setEditModal(false)
                      console.log('test')
                    }}
                    padding='0 !important'
                  >
                    <AiOutlineClose
                      color={isDarkMode ? 'white' : 'black'}
                      size={16}
                    />
                  </Button>

                  <Text fontWeight='bold' fontSize='20px'>
                    Edit Details
                  </Text>
                </HStack>
                {/* <Button onClick={updateLinks}>Save</Button> */}
              </HStack>
            </ModalHeader>
            <ModalBody textAlign='center'>
              <VStack gap='1.4rem'>
                <Box w='full' position='relative'>
                  <Box
                    bg='grey'
                    width='100%'
                    height='100px'
                    rounded='10px'
                    onMouseLeave={() => setShowEditBanner(false)}
                    onMouseEnter={() => setShowEditBanner(true)}
                  >
                    {showEditBanner && (
                      <Box
                        w='full'
                        h='100px'
                        rounded='10px'
                        position='absolute'
                        left='0'
                        top='0'
                        bgColor='#ffffff50 !important'
                        overflowY='hidden'
                      >
                        <Box position='relative' overflowY='hidden'>
                          <Box position='absolute' left='205px' top='35px'>
                            <BiCamera size={30} />
                          </Box>
                          <Input
                            onMouseLeave={() => setShowEditPic(false)}
                            accept='image/png, image/jpeg'
                            onChange={(e: any) =>
                              updateHeroImage(
                                e.target.files?.length > 0
                                  ? e.target.files[0]
                                  : null
                              )
                            }
                            overflowY='hidden'
                            py='300px'
                            w='full'
                            h='100px'
                            bgColor='#ffffff50 !important'
                            type='file'
                            className='pointer'
                          />

                          {/* <Box position='absolute' top='50%' left='50%'>
                          <BsFillImageFill color='#ffffff' size={30} />
                        </Box> */}
                        </Box>
                      </Box>
                    )}
                  </Box>
                  {gradientStart && gradientEnd && (
                    <ProfilePicture
                      onMouseEnter={() => setShowEditPic(true)}
                      imageUrl={pfp}
                      gradientstart={gradientStart}
                      gradientend={gradientEnd}
                      w='100px'
                      h='100px'
                      rounded='full'
                      position='absolute'
                      left='30px'
                      bottom='-40px'
                    />
                  )}

                  {showEditPic && (
                    <Box
                      position='absolute'
                      left='30px'
                      bottom='-40px'
                      w='100px'
                      h='100px'
                      rounded='full'
                      bgColor='#ffffff50 !important'
                    >
                      <Box
                        position='relative'
                        overflowX='hidden'
                        rounded='full'
                      >
                        <Box position='absolute' left='35px' top='35px'>
                          <BiCamera size={30} />
                        </Box>
                        <Input
                          onMouseLeave={() => setShowEditPic(false)}
                          accept='image/png, image/jpeg'
                          onChange={(e: any) =>
                            updatePfp(
                              e.target.files?.length > 0
                                ? e.target.files[0]
                                : null
                            )
                          }
                          overflowX='hidden'
                          px='100px'
                          w='100px'
                          h='100px'
                          rounded='full'
                          bgColor='#ffffff50 !important'
                          type='file'
                          className='pointer'
                        />

                        {/* <Box position='absolute' top='50%' left='50%'>
                          <BsFillImageFill color='#ffffff' size={30} />
                        </Box> */}
                      </Box>
                    </Box>
                  )}
                </Box>
              </VStack>
              <VStack marginTop='60px'>
                <HStack>
                  <TwitterLinkMultiButton></TwitterLinkMultiButton>
                  <DiscordLinkMultiButton></DiscordLinkMultiButton>
                </HStack>

                <FormControl display='flex' gap='2' flexDirection='column'>
                  <Field
                    name='name'
                    value={username}
                    onUpdate={handleUsernameUpdate}
                    submit={updateUsername}
                  />
                  <Field
                    name='bio'
                    value={bio}
                    onUpdate={handleBioUpdate}
                    submit={updateBio}
                  />

                  {links?.map((link: WebsiteLink, index: number) => {
                    return (
                      <HStack key={index}>
                        <DualField
                          index={index}
                          value={link}
                          onUpdate={handleLinkUpdate}
                          submit={link.id !== '' ? updateLink : addLink}
                          deleteLink={deleteLink}
                        />
                      </HStack>
                    )
                  })}
                  {links.length < 5 ? (
                    <HStack width='full' mt='2'>
                      <Button
                        onClick={() =>
                          setLinks((state) => [...state, emptyLink])
                        }
                        padding='0 !important'
                      >
                        <IoAdd size={16} />
                      </Button>
                      <Text>Add link</Text>
                    </HStack>
                  ) : (
                    <></>
                  )}

                  {/* <Button
                  variant={isDarkMode ? 'underlineDark' : 'underline'}
                  p='0!important'
                >
                  Change PFP
                  <Input
                    type='file'
                    height='100%'
                    width='100%'
                    position='absolute'
                    top='0'
                    left='0'
                    opacity='0'
                    aria-hidden='true'
                    accept='image/png, image/jpeg'
                    onChange={(e: any) =>
                      uploadPfp(
                        e.target.files?.length > 0 ? e.target.files[0] : null
                      )
                    }
                  />
                </Button> */}
                </FormControl>
              </VStack>
            </ModalBody>
            <ModalFooter />
          </ModalContent>
        </Modal>
      </>
    )
  }
}
