import { FC, useEffect, useMemo, useState } from 'react'
import {
  Stack,
  Box,
  HStack,
  VStack,
  Text,
  useColorMode,
  Image,
  Skeleton,
} from '@chakra-ui/react'
import { useUserByWalletInRouter } from '../../techRaffles/hooks/user'
import { useWallet } from '@solana/wallet-adapter-react'
import { useWalletFromRouter } from '../../techRaffles/hooks/user'
import { useProjectBySlug } from '../../techRaffles/hooks/project'
import { useUser } from '../../common/auth/authHooks'
import { PlayerLvl } from './PlayerLvl'
import { useRouter } from 'next/router'
import { formatFloatForDisplay } from '../../../utils/utils'
import { getLevelBasedOnPoints } from '../utils/levelUtils'

export const XpBar: FC<{ variant: string }> = ({ variant }) => {
  const { colorMode } = useColorMode()
  const isDarkMode = colorMode === 'dark'
  const liked = false
  const { publicKey: userPubKey } = useWallet()
  const walletFromRouter = useWalletFromRouter()
  const loggedInWallet = useMemo(() => {
    return userPubKey?.toBase58()
  }, [userPubKey])

  const [data, setData] = useState<any>()

  const router = useRouter()
  const { data: pathUser } = useUserByWalletInRouter()
  const { data: walletUser } = useUser()
  const { data: projectData } = useProjectBySlug()

  useEffect(() => {
    if (router.pathname.includes('/p')) {
      setData(projectData)
    } else if (router.pathname === '/rewardsCenter') {
      setData(walletUser)
    } else {
      setData(pathUser)
    }
  }, [pathUser, walletUser, router.pathname])

  const loading = useMemo(() => {
    return !data
  }, [data])

  const { level, xpNeeded } = getLevelBasedOnPoints(data?.experiencePoints)

  const percentage = (data?.experiencePoints / xpNeeded) * 100

  if (loading) {
    return <Skeleton w='full' h='260px' borderRadius='20px' />
  } else {
    return (
      <>
        <Stack
          w={variant === 'user' ? ['full'] : 'full'}
          flexDirection={
            variant === 'user'
              ? ['column']
              : ['column']
          }
          minHeight={'260px'}
          border='solid'
          borderRadius='20px'
          borderColor={isDarkMode ? '#494949' : '#E9E9E9'}
          bgColor={isDarkMode ? 'cardBlack' : '#FAFAFA'}
          justifyContent={
            variant === 'user' ? 'center' : ['center', 'center', 'space-evenly']
          }
          alignItems='center'
          paddingY='5'
          position='relative'
          gap='3'
        >
          <Box position='relative'>
            <Box
              position='absolute'
              top={variant === 'user' ? '18px' : '28px'}
              left={variant === 'user' ? '18px' : '28px'}
              zIndex='10'
              w='35px'
            >
              <PlayerLvl experiencePoints={data?.experiencePoints} />
            </Box>

            <Box
              w={variant === 'user' ? '70px' : '90px'}
              h={variant === 'user' ? '70px' : '90px'}
              position='relative'
              transform='rotate(198deg)'
            >
              <Box
                w='85%'
                h='85%'
                bgColor={isDarkMode ? 'cardBlack' : '#FAFAFA'}
                borderRadius='50%'
                position='absolute'
                clipPath='polygon(50% 0%, 100% 0%, 100% 100%, 0% 100%, 0% 0%, 50% 0%, 50% 50%)'
                top='50%'
                left='50%'
                transform='translate(-50%, -50%)'
                zIndex='10'
              ></Box>
              <Box
                w='100%'
                h='100%'
                bg={`conic-gradient(${
                  isDarkMode ? 'white' : 'black'
                } 0% ${percentage}%, transparent ${percentage}% 100%)`}
                borderRadius='50%'
                position='absolute'
                top='50%'
                left='50%'
                transform='translate(-50%, -50%)'
                zIndex='5'
              ></Box>
              <Box
                w='100%'
                h='100%'
                bg={`conic-gradient(${
                  isDarkMode ? '#7B7B7B' : '#EDEDED'
                } 0% 90%, transparent 90% 100%)`}
                borderRadius='50%'
                position='absolute'
                top='50%'
                left='50%'
                transform='translate(-50%, -50%)'
              ></Box>
            </Box>
          </Box>
          <VStack px={2}>
            <HStack>
              <Text fontWeight='bold' fontSize='20px'>
                {formatFloatForDisplay(data?.experiencePoints, 2)} /
              </Text>
              <Text fontWeight='bold' fontSize='20px' color='#7B7B7B'>
                {xpNeeded}
              </Text>
            </HStack>
            <Text color='#7B7B7B'>Lifetime Points </Text>
            <Text textAlign={'center'} fontSize='.8rem' color='#8e8e8e'>
              Lifetime points determine your level and will be useful in the future.
            </Text>
          </VStack>
        </Stack>
      </>
    )
  }
}
